import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import clsx from "clsx";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

export type RHRArrowIconProps = SvgIconProps & {
  strokeColor?: string;
  ispdpv2?: boolean;
};

export const RHRArrowIcon: FC<RHRArrowIconProps> = ({
  strokeColor,
  ispdpv2,
  ...props
}) => {
  const env = useEnv();
  const FEATURE_TAILWIND_ICON = yn(env.FEATURE_TAILWIND_ICON);
  return FEATURE_TAILWIND_ICON ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      id="RHR-arrow-icon"
      {...props}
      className={clsx("text-base !w-4 !h-4", props.className)}
    >
      <path d="M6 4L10 8L6 12" stroke="black" />
    </svg>
  ) : ispdpv2 ? (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.130192 0.112483C0.303767 -0.0375057 0.585174 -0.0374927 0.758731 0.112512L3.99965 2.91363L7.2413 0.112483C7.41488 -0.0375057 7.69629 -0.0374927 7.86984 0.112512C8.0434 0.262517 8.04338 0.505709 7.86981 0.655698L3.99959 4L0.130158 0.655669C-0.0433991 0.505664 -0.0433841 0.262472 0.130192 0.112483Z"
        fill="black"
      />
    </svg>
  ) : (
    <SvgIcon
      viewBox="0 0 5 9"
      {...props}
      className={clsx("text-[7px] !fill-none", props.className)}
    >
      <path
        d="M0.734375 0.90918L4.23437 4.40918L0.734375 7.90918"
        stroke={strokeColor}
      />
    </SvgIcon>
  );
};

export default RHRArrowIcon;
