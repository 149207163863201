import { useLocation } from "react-router";
import { getLocaleFromPath } from "@RHCommerceDev/utils/intlUtils";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { useAemFetchHook } from "@RHCommerceDev/aem/fetchModel";
import { processModelResponse } from "@RHCommerceDev/graphql-client-queries-get-aem-model";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
const stripTrailingSlash = str => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

export const convertCustomRoutes = (path: string) => {
  const isGalleryDetailsPath = new RegExp(
    /store-locations\/([0-9])+\.jsp/gm
  ).test(path);
  const { locale } = getLocaleFromPath(path) || {};

  const normalizedRawUrl = stripTrailingSlash(path);
  const urlParts = normalizedRawUrl.split("/");
  const lastPartUrl = urlParts?.[urlParts?.length - 1] || "";

  const target = path.includes(".jsp")
    ? lastPartUrl
    : normalizedRawUrl || "home";

  const targetPath = isGalleryDetailsPath
    ? "gallery-details.jsp"
    : path.includes(".jsp")
    ? target.replace("_", "-")
    : target;

  return { targetPath, locale };
};

const homePagesRegex = /^(\/(gb|us|ca|de|be|es)\/(en|de|nl|fr|es))?\/?$/;

export const useFetchModel = (
  modelPath?: string,
  isJSPRoute?: boolean,
  isPage?: boolean,
  model?: any,
  client?: any
) => {
  const location = useLocation();
  const req = processEnvServer ? getReqContext() : null;

  const path = processEnvServer ? req?.path : location.pathname;
  const isSearchPage = modelPath?.includes("/search/") || false;
  const isAemPage =
    !path?.includes(".jsp") &&
    !modelPath?.includes("admin") &&
    isPage &&
    !isSearchPage;

  if (modelPath?.includes(".jsp")) {
    const urlIndex = modelPath.indexOf(".jsp");
    modelPath = modelPath.substring(0, urlIndex + 4);
  }
  const customPagePath = isSearchPage
    ? "results"
    : modelPath && modelPath !== ""
    ? convertCustomRoutes(modelPath).targetPath.replace(".jsp", "")
    : path && path !== ""
    ? convertCustomRoutes(path).targetPath
    : "";

  const { data, loading, refetch } = useAemFetchHook({
    path: customPagePath,
    isJSPPage: isJSPRoute,
    isPage: isAemPage,
    graphqlClient: client,
    isAEMHomePage: isAemPage && homePagesRegex.test(path ?? "")
  });
  const pageContent = processModelResponse(data);
  const customPageContent =
    pageContent && Object.entries(pageContent).length > 1
      ? pageContent
      : isJSPRoute
      ? {}
      : null;
  return {
    pageContent: customPageContent,
    path: modelPath || path || "",
    loading: loading,
    error: false
  };
};
