import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useMemo } from "react";
import yn from "yn";

export const useTailwindComponent = () => {
  const env = useEnv();
  return useMemo(
    () => yn(env?.FEATURE_TAILWIND_COMPONENTS) === true,
    [env.FEATURE_TAILWIND_COMPONENTS]
  );
};
