import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

const stytchTokenType = atom("" as string);

const useSetStytchTokenTypeAtom = () => useSetAtom(stytchTokenType);
const useStytchTokenTypeValue = () => useAtomValue(stytchTokenType);
const useStytchTokenType = () => useAtom(stytchTokenType);

export {
  useSetStytchTokenTypeAtom,
  useStytchTokenType,
  useStytchTokenTypeValue
};
