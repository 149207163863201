import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import clsx from "clsx";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
export type RHArrowIconProps = SvgIconProps & {
  strokeColor?: string;
  strokeWidth?: number;
};

export const RHArrowIcon: FC<RHArrowIconProps> = ({
  strokeColor,
  strokeWidth = 0.75,
  ...props
}) => {
  const env = useEnv();
  const isTailwindIconEnabled = yn(
    env.FEATURE_TAILWIND_ICON || env.FEATURE_TAILWIND_COMPONENTS
  );
  return !isTailwindIconEnabled ? (
    <SvgIcon
      viewBox="0 0 8 15"
      {...props}
      className={clsx("text-[15px]", props.className)}
    >
      <title>Elements/Arrow</title>
      <desc>Created with Sketch.</desc>
      <g
        id="StyleGuide-+-Components"
        stroke={strokeColor ?? "none"}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="STYLES"
          transform="translate(-2306.000000, -375.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(2134.000000, 105.000000)">
            <g id="Grommet/Arrow" transform="translate(172.000000, 270.000000)">
              <path
                d="M7.87661511,7.20097449 L0.732357787,0.124954157 C0.564145597,-0.0416513858 0.294371331,-0.0416513858 0.126159142,0.124954157 C-0.0420530473,0.2915597 -0.0420530473,0.558757269 0.126159142,0.725362812 L6.96573024,7.49960706 L0.126159142,14.2738513 C-0.0420530473,14.4404569 -0.0420530473,14.7076544 0.126159142,14.87426 C0.208678329,14.955991 0.319761851,15 0.427671557,15 C0.535581263,15 0.646664785,14.9591345 0.729183972,14.87426 L7.8734413,7.79823964 C8.04165349,7.6347776 8.04165349,7.36443653 7.87661511,7.20097449 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      id="test-rh-arrow-icon"
      {...props}
      className={clsx("text-base !w-4 !h-4", props.className)}
    >
      <path d="M6 4L10 8L6 12" stroke="black" stroke-width={strokeWidth} />
    </svg>
  );
};

export default RHArrowIcon;
