import React, { FC, ReactText } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import Anime from "react-anime";
import clsx from "clsx";

type RHCheckmarkIconProps = {
  animated?: boolean;
} & SvgIconProps;

export const RHCheckmarkIcon: FC<RHCheckmarkIconProps> = ({
  animated,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 16 15"
      className={clsx("text-[15px]", "checkmark", props.className)}
      {...props}
    >
      {animated && (
        <Anime
          svg
          strokeDashoffset={el => {
            const child = el.children[0];
            const pathLength = (child as SVGPathElement)?.getTotalLength() ?? 0;
            el.setAttribute("stroke-dasharray", pathLength.toString());
            return [pathLength, 0] as unknown as ReactText;
          }}
          delay={200}
          duration={700}
          easing="easeOutQuart"
        >
          <path
            className="check"
            d="M1 7.5l5 5 9-9"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
          />
        </Anime>
      )}
      {!animated && (
        <path
          className="check"
          d="M1 7.5l5 5 9-9"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
        />
      )}
    </SvgIcon>
  );
};

RHCheckmarkIcon.defaultProps = {
  animated: false
};

export default React.memo(RHCheckmarkIcon);
