import React, { FC } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { SvgIconProps } from "@mui/material/SvgIcon";
import clsx from "clsx";
import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS
} from "utils/constants";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    arrowSVG: {
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        width: "16px",
        height: "48px",
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='48' viewBox='0 0 16 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='48' fill='%23F9F7F4'/%3E%3Cpath d='M12 32L4 24L12 16' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E")`
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: "32px",
        height: "56px",
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='32' height='56' viewBox='0 0 32 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='56' fill='%23F9F7F4'/%3E%3Cpath d='M22 40L10 28L22 16' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E")`
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: "40px",
        height: "56px",
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='40' height='56' viewBox='0 0 40 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='40' height='56' fill='%23F9F7F4'/%3E%3Cpath d='M26 40L14 28L26 16' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E")`
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        width: "80px",
        height: "56px",
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='80' height='56' viewBox='0 0 80 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='80' height='56' fill='%23F9F7F4'/%3E%3Cpath d='M46 40L34 28L46 16' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E")`
      }
    }
  });
});

export const ArrowCarouselIcon: FC<SvgIconProps> = props => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.arrowSVG, props.className)}
      style={props.style}
    />
  );
};

export default ArrowCarouselIcon;
