import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import clsx from "clsx";
import memoize from "utils/memoize";

export type RHRFlagIconProps = SvgIconProps & {
  country?: string;
};

export const RHRFlagIcon: FC<RHRFlagIconProps> = ({ country, ...props }) => {
  const USFlag = () => {
    return (
      <>
        <path d="M0 0H16V10H0V0Z" fill="white" />
        <path d="M7 0H16V1.11111H7V0Z" fill="#6E1616" />
        <path d="M7 2.22217H16V3.33328H7V2.22217Z" fill="#6E1616" />
        <path d="M7 4.44434H16V5.55545H7V4.44434Z" fill="#6E1616" />
        <path d="M0 0H7V5.55556H0V0Z" fill="#2A2939" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.950109 3.0064L0.50927 3.32307L0.674215 2.80595L0.236816 2.48455L0.779597 2.48162L0.950109 1.96631L1.12062 2.48162L1.6634 2.48455L1.226 2.80595L1.39095 3.32307L0.950109 3.0064Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.95011 3.0064L2.50927 3.32307L2.67422 2.80595L2.23682 2.48455L2.7796 2.48162L2.95011 1.96631L3.12062 2.48162L3.6634 2.48455L3.226 2.80595L3.39095 3.32307L2.95011 3.0064Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.95011 3.0064L4.50927 3.32307L4.67422 2.80595L4.23682 2.48455L4.7796 2.48162L4.95011 1.96631L5.12062 2.48162L5.6634 2.48455L5.226 2.80595L5.39095 3.32307L4.95011 3.0064Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.95011 4.67339L1.50927 4.99006L1.67422 4.47294L1.23682 4.15154L1.7796 4.14861L1.95011 3.6333L2.12062 4.14861L2.6634 4.15154L2.226 4.47294L2.39095 4.99006L1.95011 4.67339Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.95011 4.67339L3.50927 4.99006L3.67422 4.47294L3.23682 4.15154L3.7796 4.14861L3.95011 3.6333L4.12062 4.14861L4.6634 4.15154L4.226 4.47294L4.39095 4.99006L3.95011 4.67339Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.95011 4.67339L5.50927 4.99006L5.67422 4.47294L5.23682 4.15154L5.7796 4.14861L5.95011 3.6333L6.12062 4.14861L6.6634 4.15154L6.226 4.47294L6.39095 4.99006L5.95011 4.67339Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.9873 1.4497L1.52378 1.7998L1.69721 1.22809L1.2373 0.872754L1.80802 0.869519L1.9873 0.299805L2.16659 0.869519L2.7373 0.872754L2.2774 1.22809L2.45083 1.7998L1.9873 1.4497Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.95011 1.3399L3.50927 1.65657L3.67422 1.13945L3.23682 0.818042L3.7796 0.815116L3.95011 0.299805L4.12062 0.815116L4.6634 0.818042L4.226 1.13945L4.39095 1.65657L3.95011 1.3399Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.9873 1.4497L5.52378 1.7998L5.69721 1.22809L5.2373 0.872754L5.80802 0.869519L5.9873 0.299805L6.16659 0.869519L6.7373 0.872754L6.2774 1.22809L6.45083 1.7998L5.9873 1.4497Z"
          fill="white"
        />
        <path d="M0 6.6665H16V7.77762H0V6.6665Z" fill="#6E1616" />
        <path d="M0 8.88867H16V9.99978H0V8.88867Z" fill="#6E1616" />
      </>
    );
  };

  const UKFlag = () => {
    return (
      <>
        <g clipPath="url(#clip0_2576_15293)">
          <path d="M16.0003 0H0V10.0002H16.0003V0Z" fill="#080827" />
          <path
            d="M0 0V1.11806L14.2115 10.0002H16.0003V8.88221L1.78884 3.3334e-05L0 0ZM16.0003 0V1.11802L1.78884 10.0002H0V8.88218L14.2115 0H16.0003Z"
            fill="white"
          />
          <path
            d="M6.6668 0V10.0002H9.33352V0H6.6668ZM0 3.3334V6.6668H16.0003V3.3334H0Z"
            fill="white"
          />
          <path
            d="M0 4.00008V6.00012H16.0003V4.00008H0ZM7.20015 0V10.0002H8.80018V0H7.20015Z"
            fill="#692B2B"
          />
          <path
            d="M0 10.0002L5.33344 6.6668H6.526L1.19256 10.0002H0H0ZM0 0L5.33344 3.3334H4.14088L0 0.745382V0L0 0ZM9.47432 3.3334L14.8078 0H16.0003L10.6669 3.3334H9.47432ZM16.0003 10.0002L10.6669 6.6668H11.8594L16.0003 9.25485V10.0002Z"
            fill="#692B2B"
          />
        </g>
        <defs>
          <clipPath id="clip0_2576_15293">
            <rect width="16" height="10" fill="white" />
          </clipPath>
        </defs>
      </>
    );
  };

  const canadianFlag = () => {
    return (
      <>
        <rect width="16" height="9.99979" fill="white" />
        <rect width="3" height="10.0001" fill="#72303C" />
        <rect x="13" width="3" height="10.0001" fill="#72303C" />
        <path
          d="M9.5 5.9902L11 5.19021L10.25 4.79026V3.99027L8.75 4.79026L9.5 3.19027H8.75L8 1.99023L7.25 3.19027H6.5L7.25 4.79026L5.75 3.99027V4.79026L5 5.19021L6.5 5.9902L6.12505 6.79019H7.62505V7.99023H8.37505V6.79019H9.87505L9.5 5.9902Z"
          fill="#72303C"
        />
      </>
    );
  };

  const GermanyFlag = () => {
    return (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.379883H16V10.3799H0V0.379883Z"
          fill="#F0F0F0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.379883H16V3.71322H0V0.379883Z"
          fill="#080827"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.71289H16V7.04622H0V3.71289Z"
          fill="#72303C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 7.04688H16V10.3802H0V7.04688Z"
          fill="#B8A44D"
        />
      </>
    );
  };

  const BelgiumFlag = () => {
    return (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.379883H16V10.3799H0V0.379883Z"
          fill="#F0F0F0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.61816 0.379883H15.9991V10.3799H7.61816V0.379883Z"
          fill="#72303C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.379883H5.33333V10.3799H0V0.379883Z"
          fill="#080827"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.33301 0.379883H10.6663V10.3799H5.33301V0.379883Z"
          fill="#B8A44D"
        />
      </>
    );
  };

  const SpainFlag = () => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0.379395H14V10.3794H0V0.379395Z"
          fill="url(#paint0_linear_7780_58350)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0.379395H16V3.37939H0V0.379395Z"
          fill="url(#paint1_linear_7780_58350)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 7.37939H16V10.3794H0V7.37939Z"
          fill="url(#paint2_linear_7780_58350)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 3.37939H16V7.37939H0V3.37939Z"
          fill="url(#paint3_linear_7780_58350)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.66602 5.04605H4.33268V5.37938H3.66602V5.04605Z"
          fill="#DECC8E"
        />
        <path
          d="M3.27632 6.01881C3.29274 6.2158 3.47037 6.37913 3.66631 6.37913C3.86247 6.37913 4.03993 6.21508 4.05629 6.01881L4.13737 5.0458H3.19524L3.27632 6.01881ZM2.86049 5.04272C2.84529 4.86033 2.98678 4.71246 3.17025 4.71246H4.16236C4.34864 4.71246 4.48765 4.8563 4.47212 5.04272L4.38847 6.04649C4.35782 6.4143 4.03706 6.71246 3.66631 6.71246C3.29812 6.71246 2.97505 6.41736 2.94414 6.04649L2.86049 5.04272Z"
          fill="#692B2B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 5.37914H4.33333V5.71247H4L3.66667 6.37914L3.33333 5.71247H3V5.37914Z"
          fill="#692B2B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 4.37917H2.66667V6.7125H2V4.37917Z"
          fill="#692B2B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.66602 4.37917H5.33268V6.7125H4.66602V4.37917Z"
          fill="#692B2B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 4.04583C3 3.86173 3.14269 3.71249 3.33646 3.71249H3.99688C4.1827 3.71249 4.33333 3.86712 4.33333 4.04583V4.21093C4.33333 4.30384 4.26165 4.37916 4.16984 4.37916H3.16349C3.0732 4.37916 3 4.30782 3 4.21093V4.04583Z"
          fill="#692B2B"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7780_58350"
            x1="147"
            y1="0.379395"
            x2="147"
            y2="150.379"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="#F0F0F0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_7780_58350"
            x1="168"
            y1="0.379395"
            x2="168"
            y2="45.3794"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#692B2B" />
            <stop offset="1" stop-color="#C60B1F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_7780_58350"
            x1="168"
            y1="7.37939"
            x2="168"
            y2="52.3794"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#692B2B" />
            <stop offset="1" stop-color="#C60B1F" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_7780_58350"
            x1="168"
            y1="3.37939"
            x2="168"
            y2="63.3794"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B8A44D" />
            <stop offset="1" stop-color="#FFC500" />
          </linearGradient>
        </defs>
      </>
    );
  };
  const FranceFlag = () => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0H16V10H0V0Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.61719 0H15.9981V10H7.61719V0Z"
          fill="#6E1616"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0H5.33333V10H0V0Z"
          fill="#2A2939"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.33203 0H10.6654V10H5.33203V0Z"
          fill="white"
        />
      </>
    );
  };

  const getFlagIcon = country => {
    country = country?.toUpperCase();
    switch (country) {
      case "US":
        return USFlag();
      case "GB":
      case "UK":
        return UKFlag();
      case "CA":
        return canadianFlag();
      case "DE":
        return GermanyFlag();
      case "BE":
        return BelgiumFlag();
      case "ES":
        return SpainFlag();
      case "FR":
        return FranceFlag();
      default:
        return USFlag();
    }
  };
  return (
    <>
      <SvgIcon
        viewBox="0 0 16 10"
        {...props}
        className={clsx("text-black h-16px", props.className)}
      >
        {getFlagIcon(country)}
      </SvgIcon>
    </>
  );
};

export default memoize(RHRFlagIcon);
