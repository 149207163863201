import { SvgIconProps } from "@mui/material/SvgIcon";
import React, { FC } from "react";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";

export interface RHRSearchIconProps extends SvgIconProps {
  screenSize?: boolean;
}

export const RHRSearchIcon: FC<RHRSearchIconProps> = ({ ...props }) => {
  const { isConcierge } = useAppId();
  const isTailwindComponentEnabled = useTailwindComponent();
  return (
    <>
      {isTailwindComponentEnabled ? (
        <svg
          style={props.style}
          className={
            isConcierge
              ? "hidden sm:block h-[15px] stroke-current stroke-[0.75px]"
              : "hidden sm:block w-4 h-4 sm:w-6 sm:h-6 stroke-current stroke-[0.75px]"
          }
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="11"
            r="10.625"
            stroke="currentColor"
            stroke-width="0.75"
          />
          <line
            x1="19.2652"
            y1="18.7348"
            x2="24.2652"
            y2="23.7348"
            stroke="currentColor"
            stroke-width="0.75"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          style={props.style}
          className={
            isConcierge
              ? "hidden sm:block h-[15px] stroke-current stroke-[0.75px]"
              : "hidden sm:block w-4 h-4 sm:w-6 sm:h-6 stroke-current stroke-[0.75px]"
          }
        >
          <circle cx="11" cy="11" r="10.625" />
          <line x1="18.2652" y1="18.7348" x2="23.2652" y2="23.7348" />
        </svg>
      )}
      {isTailwindComponentEnabled ? (
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="sm:hidden"
          style={props.style}
        >
          <circle
            cx="8.00333"
            cy="7.33333"
            r="7.08333"
            stroke="currentColor"
            stroke-width="0.5"
          />
          <line
            x1="12.8468"
            y1="12.4899"
            x2="16.1801"
            y2="15.8232"
            stroke="currentColor"
            stroke-width="0.5"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={`${props?.screenSize ? 16 : 24}`}
          height={`${props?.screenSize ? 16 : 24}`}
          viewBox="0 0 24 24"
          fill="none"
          className="sm:hidden"
          style={props.style}
        >
          <circle
            cx="11.9987"
            cy="11.3333"
            r="7.08333"
            stroke="currentColor"
            stroke-width="0.5"
          />
          <line
            x1="16.8421"
            y1="16.4899"
            x2="20.1755"
            y2="19.8232"
            stroke="currentColor"
            stroke-width="0.5"
          />
        </svg>
      )}
    </>
  );
};

export default RHRSearchIcon;
