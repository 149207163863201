import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";

export const RHRCartIcon: FC<SvgIconProps> = props => {
  const style = {
    ...props.style
  };
  const isTailwindComponentEnabled = useTailwindComponent();

  return isTailwindComponentEnabled ? (
    <svg
      {...props}
      style={style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.65381 4.42383H14.5769V13.3469C14.5769 14.5364 13.6126 15.5008 12.423 15.5008H3.80766C2.61812 15.5008 1.65381 14.5364 1.65381 13.3469V4.42383Z"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <mask
        id="mask0_11230_789"
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="14"
        height="5"
      >
        <rect
          width="13"
          height="4.5"
          transform="matrix(1 0 0 -1 1.5 4.5)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_11230_789)">
        <path
          d="M11.2499 3.50087C11.2499 5.23249 9.84648 6.63616 8.11533 6.63616C6.38417 6.63616 4.98071 5.23249 4.98071 3.50087C4.98071 1.76925 6.38417 0.365582 8.11533 0.365582C9.84648 0.365582 11.2499 1.76925 11.2499 3.50087Z"
          stroke="currentColor"
          stroke-width="0.5"
        />
      </g>
    </svg>
  ) : (
    <SvgIcon viewBox="0 0 12 14" {...props} style={style}>
      <g fill="currentColor" stroke="currentColor" strokeWidth="0">
        <path d="M8.4716 4.45087H3.5564L3.49592 4.27999C3.35382 3.8773 3.31038 3.44641 3.36924 3.02346C3.4281 2.60051 3.58755 2.19785 3.8342 1.84927C4.08085 1.50068 4.40751 1.21633 4.78676 1.02009C5.16602 0.823845 5.58682 0.721426 6.01384 0.721426C6.44086 0.721426 6.86166 0.823845 7.24092 1.02009C7.62018 1.21633 7.94684 1.50068 8.19348 1.84927C8.44013 2.19785 8.59958 2.60051 8.65844 3.02346C8.7173 3.44641 8.67386 3.8773 8.53177 4.27999L8.4716 4.45087ZM3.9276 3.93887H8.10168C8.18506 3.61974 8.19417 3.28575 8.12831 2.96256C8.06245 2.63936 7.92337 2.33557 7.72176 2.07452C7.52016 1.81347 7.26139 1.60211 6.96534 1.45669C6.66929 1.31127 6.34384 1.23566 6.014 1.23566C5.68416 1.23566 5.35872 1.31127 5.06267 1.45669C4.76662 1.60211 4.50785 1.81347 4.30624 2.07452C4.10463 2.33557 3.96555 2.63936 3.89969 2.96256C3.83383 3.28575 3.84294 3.61974 3.92632 3.93887H3.9276Z" />
        <path d="M2.9151 13.5698C1.5391 13.5653 0.419096 12.4143 0.413656 11.0038V3.93912H11.6137V11.0034C11.6095 12.4149 10.4879 13.5663 9.1135 13.5708L2.9151 13.5698ZM0.949336 4.44984V11.0034C0.952216 12.1327 1.83446 13.0546 2.91542 13.0581H9.11222C10.1922 13.0546 11.0741 12.1327 11.078 11.0025V4.45112L0.949336 4.44984Z" />
      </g>
    </SvgIcon>
  );
};

export default RHRCartIcon;
