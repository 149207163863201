import { useMemo } from "react";
import { countryCurrencyMapper } from "@RHCommerceDev/resources/countries-config.json";

type UseCurrencyCode = {
  country: string;
  postalCode: Maybe<string>;
  userType: Maybe<string>;
};

export const useCurrencyCode = ({
  country,
  postalCode,
  userType
}: UseCurrencyCode) => {
  const currencyCode = useMemo(() => {
    return countryCurrencyMapper[country];
  }, [postalCode, userType, country]);
  return currencyCode;
};
